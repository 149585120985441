/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import PDF from "react-pdf-js-infinite"
import IRPageWrapper from "../../../../layouts/IRPageWrapper"
import IRNav from "../../../../components/IRNav"
import IRBG from "../../../../components/IRBG"
import Properties2020 from "./properties-2023.pdf"
import PropertiesNav from "../../../../components/PropertiesNav"
import HomeLogo from "../../../../components/HomeLogo"

class PropertiesPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  Our Properties
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <PropertiesNav />

          <div id="pdf-scroll">
            <PDF file={Properties2020} scale={1.5} />
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

PropertiesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default PropertiesPage
